<template>
  <div>
    <v-app-bar
      v-if="!isMobile"
      class="not-print justify-end"
      color="#eeeeee"
      app
      flat
    >
      <v-btn
        v-if="shareLinkSupported"
        @click="share()"
        class="mr-5"
        color="primary"
      >
        <v-icon left>mdi-share-variant</v-icon>
        Compartilhar
      </v-btn>
      <v-btn @click="print()" class="mr-5" color="primary">
        <v-icon left>mdi-printer</v-icon>
        Imprimir
      </v-btn>

      <v-btn @click="download()" color="primary">
        <v-icon left>mdi-download</v-icon>
        Baixar
      </v-btn>
    </v-app-bar>

    <div id="app" class="page" ref="document">
      <div v-html="document" />
    </div>
    <v-bottom-navigation
      v-if="isMobile"
      class="not-print"
      grow
      app
      color="primary"
    >
      <v-btn
        :block="isApp"
        v-if="shareLinkSupported"
        icon
        @click="share()"
        class="text-center"
        color="primary"
      >
        Compartilhar
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
      <v-btn
        :block="isApp"
        icon
        @click="$doc.print()"
        class="text-center"
        color="primary"
      >
        Imprimir
        <v-icon>mdi-printer</v-icon>
      </v-btn>

      <v-btn
        v-if="!isApp"
        icon
        @click="download()"
        class="text-center"
        color="primary"
      >
        download
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <AppEditor
      ref="AppEditor"
      class="d-none"
      v-model="documentResponse.text"
      @pdf="download"
    />
  </div>
</template>

<script>
import AppEditor from "@/components/app/forms/AppEditor";

export default {
  components: {
    AppEditor,
  },

  data() {
    return {
      documentResponse: {},
      shareLinkSupported: false,
    };
  },

  created() {
    this.shareLinkSupported = "share" in navigator;

    this.$loading.start();
    this.$http
      .show("customer/customer-documents", this.$route.params.id)
      .then((response) => {
        this.documentResponse = response.document;
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
        window.closed();
      });
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isApp() {
      return this.$webtonative.platform != "WEBSITE";
    },
    document() {
      if (this.documentResponse.text) {
        return this.$doc.convertToHtml(this.documentResponse.text);
      }
    },

    docName() {
      return `${this.documentResponse.document_name}-${this.documentResponse.customer.name}.pdf`;
    },
  },
  methods: {
    share() {
      this.$refs.AppEditor.share(this.docName);
    },

    print() {
      this.$refs.AppEditor.print();
    },

    download() {
      this.$refs.AppEditor.download(this.docName);
    },
  },
};
</script>


<style lang="sass" scoped>
#app
  font-family: 'Avenir', Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
  margin-top: 60px

.not-print
  display: flex
  justify-content: flex-center

.page
  background-color: #fff
.component
  margin-top: 5%
  align-items: center
  justify-content: center
  align-content: center
@page
  size: A4
  margin: 0.5cm
  padding: 0.5cm

@media print
  .not-print
    display: none
</style>
 